import Table from 'react-bootstrap/Table'

export default function CheatSheetTable() {
    return (
        <Table className="table table-sm table-hover table-dark" responsive>
            <thead>
                <tr>
                    <th></th>
                    <th>CALL</th>
                    <th>PUT</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>Buyer</td>
                    <td className="table-success">/ Go Up</td>
                    <td className="table-danger">\ Go Down</td>
                </tr>
                <tr>
                    <td>Seller</td>
                    <td className="table-danger">\ Go Down</td>
                    <td className="table-success">/ Go Up</td>
                </tr>
            </tbody>
        </Table>
    )
}