import React, { useState } from "react"

import 'bootstrap/dist/css/bootstrap.min.css'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import { Round } from '../../helpers/utls'
import { NumberInput } from '../../components/Inputs'
import CheatSheetTable from './CheatSheetTable'

export default function Futures({ labelWidth, inputWidth }) {
    const BUTTON_COLOR = {
        RED: "btn btn-danger dropdown-toggle",
        GREEN: "btn btn-success dropdown-toggle"
    }
    const OPTION_TYPES = {
        CALL: "CALL",
        PUT: "PUT"
    }
    const [optionType, setOptionType] = useState(OPTION_TYPES.CALL)
    const [contracts, setContracts] = useState(1)
    const [xNumberOfContracts, setXNumberOfContracts] = useState(100)
    const [strike, setStrike] = useState(110)
    const [priceLimit, setPriceLimit] = useState(5)
    const [currentPrice, setCurrentPrice] = useState(130)
    const [brokerFee, setBrokerFee] = useState(34.95)
    const [contractFee, setContractFee] = useState(0.143)
    const [showFormula, setShowFormula] = useState(false)
    const [delta, setDelta] = useState(false)
    const [startDate, setStartDate] = useState()

    const GetCurrentDate = () => {
        var now = new Date();

        var day = ("0" + now.getDate()).slice(-2)
        var month = ("0" + (now.getMonth() + 1)).slice(-2)

        var today = now.getFullYear() + "-" + (month) + "-" + (day)

        return today
    }

    const GetFutureDate = () => {
        var today = new Date()
        var future = today.getFullYear() + '-' + AddZeroToDateNumber(today.getMonth()) + '-' + AddZeroToDateNumber(today.getDate() + 7)

        return future
    }

    const AddZeroToDateNumber = (number) => {
        return number > 9 ? number : '0' + number
    }

    const FormatDollar = (number) => {
        let dollarUS = Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        });

        return dollarUS.format(number)
    }

    const IsCall = () => {
        return optionType === OPTION_TYPES.CALL
    }

    const DisplayFormula = (props) => {
        return showFormula ? <td>{props.formula}</td> : ''
    }

    const ProfitLossDifference = () => {
        return IsCall() ?
            currentPrice + BreakEven() :
            BreakEven() - currentPrice
    }

    const BreakEven = () => {
        return IsCall() ?
            Round(strike + priceLimit) :
            Round(strike - priceLimit)
    }

    const TotalProfitPercent = () => {
        return IsCall() ?
            Round((currentPrice - strike - priceLimit) / priceLimit) :
            Round((strike - currentPrice - priceLimit) / priceLimit)
    }

    const TotalProfitDollar = () => {
        return IsCall() ?
            Round((currentPrice - strike - priceLimit) * contracts * xNumberOfContracts) :
            Round((strike - currentPrice - priceLimit) * contracts * xNumberOfContracts)
    }

    const TotalPremiumCost = () => {
        return IsCall() ?
            Round(contracts * xNumberOfContracts * priceLimit + (contractFee + brokerFee)) :
            Round(contracts * xNumberOfContracts * priceLimit - (contractFee + brokerFee))
    }

    return (
        <Container>
            <Row className="mx-0">
                <h4>Stock/Crypto Options</h4>
                <Form>
                    <Form.Group className="mb-3" controlId="OptionType">
                        <Form.Label column sm={labelWidth}>
                            Option Type
                        </Form.Label>
                        <Col sm={inputWidth}>
                            <DropdownButton
                                variant={optionType == OPTION_TYPES.CALL ? BUTTON_COLOR.GREEN : BUTTON_COLOR.RED}
                                title={optionType}
                                onSelect={(e) => setOptionType(e)}>
                                <Dropdown.Item eventKey={OPTION_TYPES.CALL}>CALL</Dropdown.Item>
                                <Dropdown.Item eventKey={OPTION_TYPES.PUT}>PUT</Dropdown.Item>
                            </DropdownButton>
                        </Col>
                    </Form.Group>

                    <NumberInput
                        label={"Contracts (Qty)"}
                        symbolRight={"x"}
                        labelWidth={labelWidth}
                        inputWidth={inputWidth}
                        defaultValue={contracts}
                        onChange={(e) => setContracts(+e.target.value)}
                    />
                    <NumberInput
                        label={"Price Limit (PPS)"}
                        symbolLeft={"$"}
                        labelWidth={labelWidth}
                        inputWidth={inputWidth}
                        defaultValue={priceLimit}
                        onChange={(e) => setPriceLimit(+e.target.value)}
                    />
                    <NumberInput
                        label={"Strike (Exercise Price)"}
                        symbolLeft={"$"}
                        labelWidth={labelWidth}
                        inputWidth={inputWidth}
                        defaultValue={strike}
                        onChange={(e) => setStrike(+e.target.value)}
                    />
                    <NumberInput
                        label={"Current Price (Target)"}
                        symbolLeft={"$"}
                        labelWidth={labelWidth}
                        inputWidth={inputWidth}
                        defaultValue={currentPrice}
                        onChange={(e) => setCurrentPrice(+e.target.value)}
                    />

                    {/* <Form.Group as={Row} className="mb-3" controlId="StartDate">
                            <Form.Label column sm={labelWidth}>
                            Start Date
                            </Form.Label>
                            <Col sm={inputWidth}>
                            <Form.Control type="date" name='start_date' defaultValue={GetCurrentDate()} />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-3" controlId="EndDate">
                            <Form.Label column sm={labelWidth}>
                            End Date
                            </Form.Label>
                            <Col sm={inputWidth}>
                            <Form.Control type="date" name='end_date' defaultValue={GetFutureDate()} />
                            </Col>
                        </Form.Group> */}

                    <Container className="mb-3 bg-secondary rounded border">
                        <Row className="mb-3"></Row>
                        <NumberInput
                            label={"Broker Fee"}
                            symbolLeft={"$"}
                            labelWidth={labelWidth}
                            inputWidth={inputWidth}
                            defaultValue={brokerFee}
                            onChange={(e) => setBrokerFee(+e.target.value)}
                        />
                        <NumberInput
                            label={"Contract Fee"}
                            symbolLeft={"$"}
                            labelWidth={labelWidth}
                            inputWidth={inputWidth}
                            defaultValue={contractFee}
                            onChange={(e) => setContractFee(+e.target.value)}
                        />
                        <NumberInput
                            label={"Number of Contracts"}
                            symbolRight={"x"}
                            labelWidth={labelWidth}
                            inputWidth={inputWidth}
                            defaultValue={xNumberOfContracts}
                            onChange={(e) => setXNumberOfContracts(+e.target.value)}
                        />
                    </Container>
                </Form>
            </Row>

            <Row className="mb-3"></Row>
            <Row className="mb-3">
                <h5>
                    Calculations <Button as={Col} variant="info" onClick={() => setShowFormula(!showFormula)}>{!showFormula ? "Show" : "Hide"} Formulas</Button>
                </h5>
            </Row>
            <Table striped bordered hover variant="light" responsive>
                <thead>
                    <tr>
                        <th className={"text-end"}></th>
                        <th>Calculation</th>
                        {showFormula ? <th>Formula</th> : ''}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td className={"text-end"}>Break-even</td>
                        <td>{FormatDollar(BreakEven())}</td>
                        <DisplayFormula formula={"CALL: Strike + PriceLimit | PUT: Strike - PriceLimit"} />
                    </tr>
                    <tr>
                        <td className={"text-end"}>Total Premium Cost</td>
                        <td>{FormatDollar(TotalPremiumCost())}</td>
                        <DisplayFormula formula={"Contracts * xNumberOfContracts * Price Limit + Broker Fee + Contract Fee"} />
                    </tr>
                    <tr>
                        <td className={"text-end"}>Total Profit</td>
                        <td className={TotalProfitDollar() > 0 ? "table-success" : "table-danger"}>{FormatDollar(TotalProfitDollar())} ({TotalProfitPercent()}%)</td>
                        <DisplayFormula formula={"Price: (currentPrice - strike - priceLimit) * contracts * xNumberOfContract | Percent: (currentPrice - strike - priceLimit) / priceLimit"} />
                    </tr>
                </tbody>
            </Table>
            {showFormula ? <CheatSheetTable /> : ''}
        </Container>
    )
}