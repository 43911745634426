import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

export const NumberInput = ({ label, labelWidth, inputWidth, defaultValue, onChange, symbolLeft, symbolRight }) => {
    return (
        <Row className="mb-3">
            <Form.Label column sm={labelWidth} >
                {label}
            </Form.Label>

            <Col sm={inputWidth}>
                <InputGroup>
                    {symbolLeft ? <InputGroup.Text>{symbolLeft}</InputGroup.Text> : ''}
                    <FormControl
                        type="number"
                        placeholder={defaultValue}
                        defaultValue={defaultValue}
                        onChange={onChange}
                    />
                    {symbolRight ? <InputGroup.Text>{symbolRight}</InputGroup.Text> : ''}
                </InputGroup>
            </Col>
        </Row>
    )
}

export const LabelInput = ({ label, labelWidth, inputWidth, value }) => {
    return (
        <Row>
            <Form.Group as={Row} className="mb-3" controlId="Contracts">
                <Form.Label column sm={labelWidth}>
                    {label}
                </Form.Label>
                <Col sm={inputWidth}>
                    {value}
                </Col>
            </Form.Group>
        </Row>
    )
}