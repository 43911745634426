import React, { useEffect, useState } from "react";

import Futures from './calculators/futures/Futures'
import Options from './calculators/options/Options'
import Navigation from './components/Navigation'
import 'bootstrap/dist/css/bootstrap.min.css'
import Container from 'react-bootstrap/Container'
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function App() {
  const INPUT_WIDTH = 2
  const LABEL_WIDTH = 2
  const CALCULATORS = { 
    OPTIONS: "#options", 
    FUTURES: "#futures" 
  }
  const [calculator, setCalculator] = useState(CALCULATORS.OPTIONS);

  useEffect(() => {
    if (!getHashtag())
      setHashtag(CALCULATORS.OPTIONS)
    setCalculator(getHashtag())
  }, [])

  const getHashtag = () => {
    return window.location.hash
  }

  const setHashtag = (hashtag) => {
    setCalculator(hashtag)
    window.location = hashtag
  }

  const showCalculator = () => {
    switch (calculator) {
      case CALCULATORS.OPTIONS:
        return <Options inputWidth={INPUT_WIDTH} labelWidth={LABEL_WIDTH} />
      case CALCULATORS.FUTURES:
        return <Futures inputWidth={INPUT_WIDTH} labelWidth={LABEL_WIDTH} />
      default:
        return <Options inputWidth={INPUT_WIDTH} labelWidth={LABEL_WIDTH} />
    }
  }

  return (
    <>
      <Navigation />
      <Container>
        <Row className="mb-3"></Row>
        <Row>
          <Col></Col>
          <Col>
            <Row>
              <ToggleButtonGroup type="radio" name="options" defaultValue={getHashtag()}>
                <ToggleButton id="show-options" value={CALCULATORS.OPTIONS} onClick={() => setHashtag(CALCULATORS.OPTIONS)}>
                  Stock/Crypto Options
                </ToggleButton>
                <ToggleButton id="show-crypto-futures" value={CALCULATORS.FUTURES} onClick={() => setHashtag(CALCULATORS.FUTURES)}>
                  Crypto Futures
                </ToggleButton>
              </ToggleButtonGroup>
            </Row>
          </Col>
          <Col></Col>
        </Row>
        <Row className="mb-3"></Row>

        {showCalculator()}
      </Container>
    </>
  );
}

export default App;