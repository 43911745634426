import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

export default function Navigation() {
    return (
        <Navbar bg="light" variant="light">
            <Container>
                <Navbar.Brand href="#home">
                    <div><strong>Options Calculator -</strong> U4SYSTEMS</div>
                </Navbar.Brand>
            </Container>
        </Navbar>
    )
}