import React, { useState } from "react"

import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import { Round } from '../../helpers/utls'
import { NumberInput, LabelInput } from '../../components/Inputs'

export default function Futures({ labelWidth, inputWidth }) {
    const [leveragePercentProfit, setLeveragePercentProfit] = useState(0.93)
    const [leverage, setLeverage] = useState(20)

    return (
        <Container>
            <h4>Crypto Futures</h4>
            <Row className="mx-0">
                <NumberInput
                    label={"Percent Profit"}
                    symbolRight={"%"}
                    labelWidth={labelWidth}
                    inputWidth={inputWidth}
                    defaultValue={leveragePercentProfit}
                    onChange={(e) => setLeveragePercentProfit(+e.target.value)}
                />
                <NumberInput
                    label={"Leverage"}
                    symbolRight={"x"}
                    labelWidth={labelWidth}
                    inputWidth={inputWidth}
                    defaultValue={leverage}
                    onChange={(e) => setLeverage(+e.target.value)}
                />
                <LabelInput
                    label={"Percent Move"}
                    labelWidth={labelWidth}
                    inputWidth={inputWidth}
                    value={`${Round(leverage * leveragePercentProfit)}%`}
                />
            </Row>
        </Container>
    )
}